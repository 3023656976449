import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

// import DataProtected from "../../assets/images/security/DataProtected.png";
// import SSL from "../../assets/images/security/SSL.png";
// import Clear from "../../assets/images/security/Clear.png";

import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";

import { RatesFee } from "../popups/RatesFee/RatesFee";
import { SecuritySeal } from "../SecuritySeal/SecuritySeal";
import {
  // Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  InputAdornment
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import "./Banner.scss";
import {
  MenuProps,
  btnSubmitCustom,
  labelCustom,
  menuItemCustom,
  selectCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import { amountOptions } from "../../utils/options";
import axios from "axios";
import { handleLastStep } from "../../utils/functions";
import { sendDataBrevo } from "../../utils/callApi";
import { LoadingFull } from "../LoadingFull/LoadingFull";

import { loanReasonOptions } from "../../utils/options";
import { config } from "../../utils/const";
import { addTemporaryContact } from "../../redux/contactRedux";
import { zipCodeRequest } from "requestMethods";

const Banner = () => {
  const windowWidth = window.innerWidth;
  const isMobile = window.innerWidth < 768;

  const [openRateFee, setOpenRateFee] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const temporaryContact = useSelector((state) => state?.contact?.temporary);

  const [amount, setAmount] = useState(
    contactRedux?.amount ?? contactRedux?.loanAmount ?? amountOptions[3]?.value
  );

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState( temporaryContact?.zip ?? contactRedux?.zip ?? contactRedux?.zipCode ?? "");
  const [loadingZip, setLoadingZip] = useState(false);

  const jornayaRef = useRef(null);
  const [jornayaLeadId, setJornayaLeadId] = useState("");

  const [loanReason, setLoanReason] = useState(
    contactRedux?.loanReason ?? loanReasonOptions[3].value
  );

  const [ipAddress, setIpAddress] = useState(contactRedux?.ipAddress ?? "");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const emailFromURL = searchParams.get("email");
  const loanReasonFromURL = searchParams.get("loan_reason");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // set sessionStorage
  loanReasonFromURL &&
    sessionStorage.setItem("loanReasonFromURL", loanReasonFromURL);

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const yourIp = await axios.get(
          `${config.apiBaseUrl}/your-ip`
        );
        const res = yourIp?.data.ip;
        if (res) {
          setIpAddress(res);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    // get ip address if ipAddress not exist
    !ipAddress && getIpAddress();

    const fetchData = async () => {
      if (emailFromURL && loanReasonFromURL && ipAddress) {
        setShowLoading(true);
        try {
          // Dispatch data to Redux
          const res = await addContact(dispatch, {
            email: emailFromURL,
            loanReason: loanReasonFromURL,
            ipAddress,
            userAgent: navigator.userAgent
          });
          // Send data to email phonexa
          sendDataBrevo(emailFromURL, "", "", [47], loanReasonFromURL);
          // check last step and navigator to next page
          const lastStep = handleLastStep(res?.contact);
          setShowLoading(false);
          lastStep && navigate(`/${lastStep}`);
        } catch (error) {
          setShowLoading(false);
          console.error("Error processing data:", error);
        }
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddress, emailFromURL, loanReasonFromURL]);

  // jornaya lead id
  useEffect(() => {
    const checkValue = () => {
      const value = jornayaRef.current?.value;
      if (value) {
        setJornayaLeadId(value);
      } else {
        setTimeout(checkValue, 1000);
      }
    };
    checkValue();
    return () => {
    };
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    reValidateMode: 'onChange'
  });

  const onSubmit = async (data) => {
    if (loadingButton) {
      return false;
    }
    try {
      setLoadingButton(true);

      const res = await checkZipCode(data.zipCode);
      if (res) {
        dispatch(
          addTemporaryContact({
            ...data,
            ...res,
            zip: zipCode,
            loanAmount: amount,
            jornayaLeadId: jornayaLeadId || undefined
          })
        );
        setLoadingButton(false);
        navigate(`/lgname`);
      } else {
        setError("zipCode", {
          type: "manual",
          message: "Invalid",
        });
        setLoadingButton(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoadingButton(false);
    }
  };

  const checkZipCode = async (zip) => {
    if (loadingZip) return
    setLoadingZip(true);

    const res = await zipCodeRequest.get("",{ params: { lead_id: ipAddress, zip: zipCode} }
    ).catch((err)=> {return false}
    ).finally(() =>setLoadingZip(false));
    const result = res?.data?.data;
    if (result?.city) {
      setState(result.state);
      setCity(result.city);
      return {state: result.state, city: result.city, lat: result?.lat, lon: result?.long};
    } else {
      return false;
    }
  };

  return (
    <>
      {showLoading && <LoadingFull />}
      <div className="banner" id="banner">
        {openRateFee && (
          <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
        )}
        <div className="container">
          <Box
            className="banner-box"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isMobile ? "0" : "40px",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <Box
              sx={{
                flex: 1,
                maxWidth: isMobile ? "309px" : "auto",
                textAlign: "center",
                position: "relative",
                zIndex: "1"
              }}
            ></Box>
            <Box
              sx={{
                flex: 1,
                padding: isMobile ? "19px 14px 20px" : "19px 47px 20px",
                background: "#FFF",
                borderRadius: "4px"
              }}
            >
              <h2 className="title">
                Get Fast and Reliable Loans up to $5,000
              </h2>
              {/* FORM */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="amount"
                  defaultValue={amount}
                  control={control}
                  rules={{ required: "Loan amount is required!" }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.amount}
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Loan Amount ($100 - $5,000)
                      </FormHelperText>
                      <Select
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setAmount(value);
                        }}
                        value={amount}
                        displayEmpty
                        MenuProps={{
                          ...MenuProps,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                          }
                        }}
                        sx={selectCustom}
                        onClose={() => {
                          setIsSelectOpen(false);
                        }}
                        onOpen={() => {
                          setIsSelectOpen(true);
                        }}
                        className={isSelectOpen ? "show" : ""}
                      >
                        {amountOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.amount && errors?.amount.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />

                <Controller
                  name="loanReason"
                  defaultValue={loanReason}
                  control={control}
                  rules={{ required: "Loan reason is required!" }}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      error={!!errors?.loanReason}
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        marginBottom: isMobile ? "7px" : "15px"
                      }}
                    >
                      <FormHelperText sx={labelCustom}>
                        Reason for loan
                      </FormHelperText>
                      <Select
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value);
                          setLoanReason(value);
                        }}
                        value={loanReason}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={selectCustom}
                        onClose={() => {
                          setIsSelectOpen(false);
                        }}
                        onOpen={() => {
                          setIsSelectOpen(true);
                        }}
                        className={isSelectOpen ? "show" : ""}
                      >
                        {loanReasonOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.value}
                            sx={menuItemCustom}
                            size="small"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors?.loanReason && errors?.loanReason.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />

                <FormHelperText sx={labelCustom}>Your Zip Code</FormHelperText>
                <Controller
                  name="zipCode"
                  control={control}
                  defaultValue={zipCode}
                  rules={{
                    required: "Zip code is required!",
                    minLength: {
                      value: 5,
                      message: "Zip code must be 5 characters long!"
                    }
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="zipCode"
                      sx={{ ...textFieldCustom, marginBottom: "7px" }}
                      // autoComplete="zipCode"
                      variant="outlined"
                      fullWidth
                      type="text"
                      error={!!errors?.zipCode}
                      label={zipCode ? " " : "e.g. 90011"}
                      helperText={errors?.zipCode && errors?.zipCode?.message}
                      inputProps={{
                        maxLength: 5
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: { ...textInputStyles, color: "#B7B7B7" }
                      }}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        field.onChange(value);
                        setZipCode(value);
                        setState(null);
                        setCity(null);
                      }}
                      value={zipCode}
                      size={isMobile ? "small" : "medium"}
                      InputProps={{
                        endAdornment: loadingZip && (
                          <InputAdornment position="end">
                            <CircularProgress
                              size={windowWidth > 768 ? 20 : 15}
                              color="inherit"
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                {city && state && (
                  <div className="box-state">
                    <LocationOnIcon className="address-icon" sx={{ width: 20 }} />
                    <p className="state">
                      {city}, {state}
                    </p>
                  </div>
                )}

                {/* jornaya */}
                <input
                  ref={jornayaRef}
                  id="leadid_token"
                  name="universal_leadid"
                  type="hidden"
                  value=""
                />

                <p className="tcpa">
                  By clicking <b>'Request Now'</b>, you agree to our{" "}
                  <Link target="_blank" to="/privacy">Privacy Policy</Link>,{" "}
                  <Link target="_blank" to="/terms-of-use">Terms</Link>
                  {", "}
                  <Link onClick={() => setOpenRateFee(!openRateFee)}>
                    Rate & Fees
                  </Link>{" "}
                  and receive special offers from us and our <Link target="_blank" to="/partners">marketing partners</Link>{" "}
                   via email communication
                </p>
                <LoadingButton
                  endIcon={<></>}
                  // endIcon={<SendIcon />}
                  loading={loadingButton}
                  loadingPosition="end"
                  variant="contained"
                  size="large"
                  style={btnSubmitCustom}
                  // onClick={() => {
                  //   handleSubmit(onSubmit);
                  // }}
                  type="submit"
                >
                  <span>REQUEST NOW</span>
                </LoadingButton>
              </form>

              <SecuritySeal />
              
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Banner;
